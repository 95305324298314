:root {
	--sportsbook-bg-primary: rgba(var(--primary-accent-grey), 1);
	--sportsbook-bg-secondary: rgba(var(--primary-accent-grey-dark), 1);
	--sportsbook-bg-tertiary: rgba(var(--base-09), 1);
	--sportsbook-root-bg-color: var(--sportsbook-bg-secondary);
	--sportsbook-text-main: rgba(var(--font-light), 1);
	--sportsbook-text-supportive: rgba(var(--font-supportive), 1);
	--sportsbook-text-contrast: rgba(var(--font-dark), 1);
	--sportsbook-icon-primary: rgba(var(--primary), 1);
	--sportsbook-icon-supportive: rgba(var(--primary-light), 1);
	--sportsbook-rounded-sm: 3px;
	--sportsbook-rounded-md: 6px;
	--sportsbook-rounded-lg: 12px;
	--sportsbook-border-primary: rgba(var(--primary-accent-grey-light), 1);
	--sportsbook-border-secondary: rgba(var(--primary-accent-grey-dark), 1);
	--sportsbook-shadow-primary: 0 8px 10px -5px rgb(0 0 0 / 20%), 0 16px 24px 2px rgb(0 0 0 / 14%),
		0 6px 30px 5px rgb(0 0 0 / 12%);
	--sportsbook-shadow-secondary: 0 0 8px 1px rgba(0, 0, 0, 25%);

	// Explore
	--sportsbook-explore-featured-link-bg-color: none;
	--sportsbook-explore-featured-link-border: 1px solid rgba(var(--primary), 1);
	--sportsbook-explore-featured-link-text-color: rgba(var(--primary), 1);
	--sportsbook-explore-featured-live-icon-color: rgba(var(--primary-accent-green-light), 1);

	// Checkbox
	--sportsbook-checkbox-icon-color: rgba(var(--primary), 1);
	--sportsbook-checkbox-icon-checked-color: rgba(var(--primary), 1);
	--sportsbook-checkbox-label-color: var(--sportsbook-text-supportive);
	--sportsbook-checkbox-label-checked-color: rgba(var(--primary), 1);

	// Navigation
	--sportsbook-navigation-back-button-color: rgba(var(--primary), 1);

	// Breadcrumbs
	--sportsbook-breadcrumbs-text-color: var(--sportsbook-text-supportive);
	--sportsbook-breadcrumbs-text-color-hover: var(--sportsbook-text-main);

	// Desktop Menu
	--sportsbook-desktop-menu-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-desktop-menu-item-bg-color: var(--sportsbook-bg-secondary);
	--sportsbook-list-item-text-color: var(--sportsbook-text-main);
	--sportsbook-desktop-menu-item-icon-color: rgba(var(--primary-light), 1);
	--sportsbook-desktop-menu-list-title-color: var(--sportsbook-text-main);
	--sportsbook-desktop-menu-header-link-color: rgba(var(--primary), 1);
	--sportsbook-desktop-menu-chevron-color: rgba(var(--primary), 1);

	// Betslip
	--sportsbook-betslip-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-minimized-betslip-header-bg-color: rgba(var(--primary-accent-green), 1);
	--sportsbook-betslip-border: var(--sportsbook-border-primary);
	--sportsbook-betslip-header-title-color: var(--sportsbook-text-main);
	--sportsbook-minimized-betslip-header-title-color: var(--sportsbook-text-contrast);
	--sportsbook-betslip-header-bets-count-label-color: var(--sportsbook-text-contrast);
	--sportsbook-minimized-betslip-header-bets-count-label-color: rgba(var(--primary-light), 1);
	--sportsbook-betslip-header-bets-count-circle-color: rgba(var(--primary-light), 1);
	--sportsbook-minimized-betslip-header-bets-count-circle-color: var(--sportsbook-text-contrast);
	--sportsbook-betslip-header-icon-color: rgba(var(--primary), 1);
	--sportsbook-minimized-betslip-header-icon-color: var(--sportsbook-text-contrast);
	--sportsbook-betslip-clear-selections-button-text: rgba(var(--primary), 1);
	--sportsbook-betslip-remove-icon: var(--sportsbook-text-supportive);
	--sportsbook-betslip-restriction-icon: rgba(var(--info), 1);
	--sportsbook-betslip-freebet-select-bg: var(--sportsbook-bg-primary);
	--sportsbook-betslip-message-bg-color: rgba(var(--info), 1);
	--sportsbook-betslip-message-text-color: var(--sportsbook-text-main);
	--sportsbook-betslip-message-action-button-bg-color: rgba(49, 55, 55, 80%);
	--sportsbook-betslip-message-action-button-text-color: var(--sportsbook-text-main);
	--sportsbook-betslip-place-bets-button-disabled-bg: var(--button-disabled-bg);
	--sportsbook-betslip-place-bets-button-disabled-text: var(--button-disabled-text);
	--sportsbook-betslip-radio-button-color: var(--sportsbook-text-supportive);
	--sportsbook-betslip-radio-button-checked-color: rgba(var(--primary), 1);
	--sportsbook-betslip-system-bets-title-bg: var(--sportsbook-bg-secondary);
	--sportsbook-is-prominent-color: rgba(var(--primary), 1);
	--sportsbook-betslip-spinner: rgba(var(--font-dark), 1);
	--sportsbook-betslip-icon-color: rgba(var(--primary-light), 1);

	// We use them to color bet indicator and header icon circle with respect to placement state
	--sportsbook-betslip-placement-state-color-placing: rgba(var(--primary-accent-green-light), 1);
	--sportsbook-betslip-placement-state-color-placed: rgba(var(--success), 1);
	--sportsbook-betslip-placement-state-color-new-offer: rgba(var(--info), 1);
	--sportsbook-betslip-placement-state-color-declined: rgba(var(--error), 1);
	--sportsbook-betslip-placement-state-color-unknown: rgba(var(--error), 1);

	// Both icons displayed on bg with colors from set above.
	--sportsbook-betslip-placement-icon-new-offer-color: var(--sportsbook-text-main);
	--sportsbook-betslip-placement-icon-declined-color: var(--sportsbook-text-main);
	--sportsbook-betslip-placement-icon-unknown-color: var(--sportsbook-text-main);
	--sportsbook-betslip-placement-my-bets-label-color: rgba(var(--primary), 1);
	--sportsbook-betslip-placement-interaction-bg-error-color: rgba(var(--error), 1);
	--sportsbook-betslip-placement-interaction-bg-info-color: rgba(var(--info), 1);
	--sportsbook-betslip-placement-interaction-text-error-color: var(--sportsbook-text-main);
	--sportsbook-betslip-placement-interaction-text-info-color: var(--sportsbook-text-main);
	--sportsbook-betslip-placement-new-offer-highlighted-color: rgba(var(--info), 1);
	--sportsbook-betslip-placement-new-offer-button-color: rgba(49, 55, 55, 80%);
	--sportsbook-betslip-placement-new-offer-button-text-color: var(--sportsbook-text-main);

	// Share betslip
	--sportsbook-share-betslip-copy-input-bg: var(--sportsbook-bg-primary);
	--sportsbook-share-betslip-copy-input-icon-color: rgba(var(--primary), 1);

	// Event
	--sportsbook-event-status-indicator-bg-color: var(--sportsbook-bg-tertiary);
	--sportsbook-event-scoreboard-score-color: var(--sportsbook-text-main);
	--sportsbook-event-scoreboard-total-score-bg: rgba(var(--primary-accent-grey-light), 1);
	--sportsbook-event-scoreboard-participant-color: var(--sportsbook-text-main);
	--sportsbook-event-statistics-icon-color: var(--sportsbook-icon-primary);
	--sportsbook-event-dropdown-icon-color: rgba(var(--primary), 1);
	--sportsbook-event-selection-button-color: var(--selection-button-color);
	--sportsbook-event-bet-builder-prompt-icon-color: rgba(var(--primary-light), 1);

	// Top Bar
	--sportsbook-top-bar-bg-color: var(
		--base-10
	); // Intentionally set this as rgb because we need to be able to use different alpha values

	--sportsbook-top-bar-button-bg: var(--pill-bg);

	// Browse
	--sportsbook-browse-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-browse-link-color: var(--sportsbook-text-main);
	--sportsbook-browse-link-sport-icon-color: rgba(var(--primary-light), 1);
	--sportsbook-browse-link-icon-color: rgba(var(--primary), 1);
	--sportsbook-browse-leagues-expanded-color: rgba(var(--primary), 1);

	// Sport All Leagues
	--sportsbook-leagues-by-region-menu-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-leagues-by-region-region-bg-color: var(--sportsbook-bg-secondary);
	--sportsbook-league-outrights-selection-button-color: var(--selection-button-color);

	// My-bets
	--sportsbook-my-bets-settlement-status-open-color: rgba(var(--pending), 1);
	--sportsbook-my-bets-settlement-status-won-color: rgba(var(--success), 1);
	--sportsbook-my-bets-settlement-status-lost-color: rgba(var(--error), 1);
	--sportsbook-my-bets-settlement-status-cancelled-color: var(--sportsbook-text-supportive);
	--sportsbook-my-bets-show-more-color: var(--sportsbook-icon-primary);
	--sportsbook-my-bets-unauthenticated-icon-color: var(--sportsbook-text-main);
	--sportsbook-my-bets-unauthenticated-text-color: var(--sportsbook-text-main);
	--sportsbook-my-bets-ticket-bg: var(--sportsbook-bg-primary);
	--sportsbook-button-group-tab-bg: var(--sportsbook-bg-tertiary);
	--sportsbook-button-group-tab-active-bg: var(--sportsbook-bg-primary);
	--sportsbook-button-group-tab-label-color: rgba(var(--primary), 1);
	--sportsbook-my-bets-ticket-border-color: var(--sportsbook-border-primary);
	--sportsbook-my-bets-cashout-button-text-color: var(--sportsbook-text-contrast);
	--sportsbook-my-bets-cashout-button-text-color-active: rgba(var(--primary), 1);
	--sportsbook-my-bets-share-button-bg: rgba(var(--primary-accent-grey-dark), 1);
	--sportsbook-my-bets-share-text: rgba(var(--primary), 1);
	--sportsbook-my-bets-share-icon: rgba(var(--primary), 1);
	--sportsbook-my-bets-expand-color: var(--primary-accent-grey);
	--sportsbook-my-bets-login-prompt-icon-color: rgba(var(--primary-light), 1);

	// My-bets Share-Bet
	--sportsbook-my-bets-share-bet-wrapper-bg: var(--sportsbook-bg-primary);
	--sportsbook-my-bets-share-bet-header-bg: var(--sportsbook-bg-primary);
	--sportsbook-my-bets-share-bet-footer-bg: var(--sportsbook-bg-primary);
	--sportsbook-my-bets-share-bet-card-bg: var(--sportsbook-my-bets-ticket-bg, rgba(var(--sportsbook-bg-primary), 1));
	--sportsbook-my-bets-share-bet-card-icon-fill: var(--sportsbook-bg-tertiary);

	// Sportsbook filter bar
	--sportsbook-scroll-direction-arrow-bg: rgba(var(--primary-accent-grey-light), 1);
	--sportsbook-scroll-direction-arrow-color: rgba(var(--primary-accent-green), 1);

	// Sportsbook CarouselItem
	--sportsbook-carousel-promotion-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-carousel-promotion-combo-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-carousel-promotion-combo-label-color: var(--sportsbook-text-main);
	--sportsbook-carousel-promotion-combo-event-name-color: rgba(var(--font-supportive), 1);
	--sportsbook-carousel-promotion-combo-button-bg-color: rgba(var(--primary-accent-green), 1);
	--sportsbook-carousel-promotion-combo-button-bg-color-hover: rgba(var(--primary-accent-green-light), 1);
	--sportsbook-carousel-promotion-combo-button-text-color: rgba(var(--font-dark), 1);
	--sportsbook-carousel-promotion-combo-button-text-color-hover: rgba(var(--font-dark), 1);
	--sportsbook-carousel-promotion-link-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-carousel-promotion-link-label-color: var(--sportsbook-text-main);
	--sportsbook-carousel-promotion-header-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-carousel-promotion-header-title-color: var(--sportsbook-text-main);
	--sportsbook-carousel-promotion-header-label-color: var(--font-supportive);
	--sportsbook-carousel-promotion-single-label-color: var(--sportsbook-text-main);
	--sportsbook-carousel-promotion-description-bg-color: var(--base-11);
	--sportsbook-carousel-promotion-description-text-color: var(--sportsbook-text-main);
	--sportsbook-carousel-promotion-badge-bg-color: rgba(var(--primary), 1);
	--sportsbook-carousel-promotion-badge-text-color: var(--sportsbook-text-main);
	--sportsbook-carousel-promotion-nbn-title-color: var(--sportsbook-text-main);
	--sportsbook-carousel-promotion-nbn-subtitle-color: var(--sportsbook-text-supportive);
	--sportsbook-carousel-promotion-nbn-bg-color: var(--sportsbook-bg-primary);

	// Precanned Carousel
	--sportsbook-carousel-card-icon-fill: var(--sportsbook-bg-tertiary);

	// Recommended cards
	--sportsbook-recommended-card-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-recommended-card-bg-gradient-top-color: rgba(var(--primary), 0.15);
	--sportsbook-recommended-card-bg-gradient-bottom-color: rgba(var(--primary-accent-grey-dark), 0.15);
	--sportsbook-recommended-card-header-bg-gradient-left-color: rgba(var(--primary-accent-grey-dark), 0.5);
	--sportsbook-recommended-card-header-bg-gradient-right-color: rgba(var(--primary-accent-grey-dark), 0);
	--sportsbook-recommended-card-border-color: rgba(var(--base-01), 0.05);
	--sportsbook-recommended-card-icon-bg-color: rgba(var(--primary-light), 1);
	--sportsbook-recommended-card-icon-color: var(--sportsbook-bg-secondary);

	// Sportsbook Search
	--sportsbook-search-fullscreen-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-search-input-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-search-input-border-color: rgba(var(--base-05), 1);
	--sportsbook-search-input-placeholder-color: var(--sportsbook-text-supportive);
	--sportsbook-search-input-icon-color: var(--sportsbook-icon-primary);
	--sportsbook-search-input-color: rgba(var(--primary), 1);
	--sportsbook-search-result-border-color: var(--sportsbook-border-primary);
	--sportsbook-search-close-button-text-color: rgba(var(--primary), 1);
	--sportsbook-search-suggestions-history-icon-color: rgba(var(--primary-light), 1);
	--sportsbook-search-suggestions-clear-icon-color: var(--sportsbook-icon-primary);

	// Sportsbook Maintenance
	--sportsbook-maintainance-product-bg-color: rgba(var(--primary-accent-green-light), 1);
	--sportsbook-maintainance-product-icon-color: var(--sportsbook-text-contrast);
	--sportsbook-maintainance-product-label-color: var(--sportsbook-text-main);
	--sportsbook-maintainance-border-color: var(--sportsbook-border-secondary);
	--sportsbook-loading-spinner-color: rgba(var(--base-02), 1);

	// Event Widget
	--sportsbook-event-widget-bar-bg: var(--sportsbook-bg-primary);
	--sportsbook-event-widget-button-color: var(--sportsbook-text-supportive);
	--sportsbook-event-widget-button-color-active: rgba(var(--primary), 1);

	// Other
	--sportsbook-live-color: rgba(var(--primary-accent-yellow), 1);
	--animated-checkmark-stroke-color: rgba(var(--font-dark), 1);
	--sportsbook-live-text-color: var(--sportsbook-text-contrast);
	--sportsbook-no-odds-available: var(--sportsbook-text-supportive);
	--sportsbook-horizontal-filter-bar-text-color: var(--sportsbook-text-main);
	--sportsbook-horizontal-filter-bar-selected-color: rgba(var(--primary), 1);
	--sportsbook-navigation-tab-color: var(--sportsbook-text-supportive);
	--sportsbook-navigation-tab-active-color: rgba(var(--primary), 1);
	--sportsbook-navigation-tab-disabled-color: rgba(var(--font-disabled), 1);
	--sportsbook-navigation-tab-border-color: var(--sportsbook-border-primary);
	--sportsbook-header-title-color: var(--sportsbook-text-main);
	--sportsbook-connection-border-color: var(--sportsbook-text-supportive);
	--sportsbook-highlighted-label-color: var(--sportsbook-text-main);
	--sportsbook-highlighted-label-bg: rgba(var(--info), 1);
	--sportsbook-tennis-serve-indicator-color: rgba(var(--primary-light), 1);
	--sportsbook-odds-updating-bg: rgba(var(--primary-accent-grey-light), 1);
}
