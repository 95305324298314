/* 
	The CSS in this file is generated from Sportradar's demo documentation: https://widgets.sir.sportradar.com/widgets-demo
	Sportradar is calculating some of the colors based on the 5 colors you enter on their site,
	so if you change anything in this file you should consider re-generating the entire thing.
*/

/*
Using formula solid
$primary: #39F56A;
$home: #C0F9C8;
$away: #f9f9f9;
$cl: #F9F9F9;
$base: #292F2A;
*/

.sr-bb {
	background: #292f2a;
	font-family: museo-sans, sans-serif;
	text-align: left;
}

.sr-bb.sr-rtl {
	text-align: right;
}

.sr-bb .sr-bb {
	background: none;
}

.sr-bb .srt-base-1 {
	background-color: transparent;
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-base-1-win {
	background-color: transparent;
	border-color: #c0f9c8;
	color: #c0f9c8;
}

.sr-bb .srt-base-1-draw {
	background-color: transparent;
	border-color: rgba(249, 249, 249, 40%);
	color: rgba(249, 249, 249, 40%);
}

.sr-bb .srt-base-1-lose {
	background-color: transparent;
	border-color: #f9f9f9;
	color: #f9f9f9;
}

.sr-bb .srt-base-1-is-active {
	background-color: rgba(57, 245, 106, 12%);
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-base-1-is-active-2 {
	background-color: #4f544f;
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-base-1-is-hoverable:hover {
	background-color: rgba(57, 245, 106, 12%);
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-base-1-primary {
	background-color: transparent;
	border-color: #39f56a;
	color: #39f56a;
}

.sr-bb .srt-base-1-home-1 {
	background-color: transparent;
	border-color: #c0f9c8;
	color: #c0f9c8;
}

.sr-bb .srt-base-1-away-1 {
	background-color: transparent;
	border-color: #f9f9f9;
	color: #f9f9f9;
}

.sr-bb .srt-base-1-home-2 {
	background-color: transparent;
	border-color: #8af7a6;
	color: #c0f9c8;
}

.sr-bb .srt-base-1-away-2 {
	background-color: transparent;
	border-color: #8af7a6;
	color: #f9f9f9;
}

.sr-bb .srt-base-1-home-3 {
	background-color: transparent;
	border-color: rgba(249, 249, 249, 12%);
	color: #c0f9c8;
}

.sr-bb .srt-base-1-away-3 {
	background-color: transparent;
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-base-1-home-4 {
	background-color: transparent;
	border-color: #98c29e;
	color: #98c29e;
}

.sr-bb .srt-base-1-away-4 {
	background-color: transparent;
	border-color: #3b3fc5;
	color: #3b3fc5;
}

.sr-bb .srt-base-1-home-5 {
	background-color: transparent;
	border-color: #d4f9d9;
	color: #d4f9d9;
}

.sr-bb .srt-base-1-away-5 {
	background-color: transparent;
	border-color: #7d7ffd;
	color: #7d7ffd;
}

.sr-bb .srt-base-1-background {
	background-color: #292f2a;
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-base-2 {
	background-color: #292f2a;
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-base-2-is-active {
	background-color: #3c5742;
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-base-2-is-hoverable:hover {
	background-color: #3c5742;
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-base-3 {
	background-color: #3c5742;
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-base-3-is-active {
	background-color: #3b6044;
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-base-3-is-hoverable:hover {
	background-color: #3b6044;
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-base-3-background {
	background-color: #292f2a;
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-base-4 {
	background-color: #3c4b3e;
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-base-5-is-active {
	background-color: #3b7148;
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-base-5-is-hoverable:hover {
	background-color: #3b7148;
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-base-6 {
	background-color: transparent;
	border-color: rgba(249, 249, 249, 30%);
	color: #f9f9f9;
}

.sr-bb .srt-primary-1 {
	background-color: #39f56a;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-1-is-active {
	background-color: #33dd5f;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-1-is-hoverable:hover {
	background-color: #33dd5f;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-1-is-disabled {
	background-color: #2ec455;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-2 {
	background-color: #73f695;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-3 {
	background-color: #33dd5f;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-4 {
	background-color: #3aad58;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-5 {
	background-color: #3aad58;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-5-is-hoverable:hover {
	background-color: #39f56a;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-6 {
	background-color: #8af7a6;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-7 {
	background-color: #39f56a;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-8 {
	background-color: #39f56a;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-8-is-active-1 {
	background-color: #33dd5f;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-8-is-active-2 {
	background-color: #33dd5f;
	border-color: #292f2a;
	color: #000;
}

.sr-bb .srt-primary-9 {
	background-color: #3b784a;
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-primary-10 {
	background-color: #3ad161;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-11 {
	background-color: #33dd5f;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-primary-12 {
	background-color: #39f56a;
	border-color: #000;
	color: #000;
}

.sr-bb .srt-primary-13 {
	background-color: #3c4b3e;
	border-color: rgba(57, 245, 106, 30%);
	color: #000;
}

.sr-bb .srt-base-1-primary-1 {
	background-color: transparent;
	border-color: #39f56a;
	color: #39f56a;
}

.sr-bb .srt-base-1-primary-2 {
	background-color: transparent;
	border-color: #73f695;
	color: #73f695;
}

.sr-bb .srt-base-1-primary-3 {
	background-color: transparent;
	border-color: #33dd5f;
	color: #33dd5f;
}

.sr-bb .srt-base-1-primary-4 {
	background-color: transparent;
	border-color: #3aad58;
	color: #3aad58;
}

.sr-bb .srt-base-1-primary-5 {
	background-color: transparent;
	border-color: #3aad58;
	color: #3aad58;
}

.sr-bb .srt-base-1-primary-6 {
	background-color: transparent;
	border-color: #8af7a6;
	color: #8af7a6;
}

.sr-bb .srt-base-1-primary-7 {
	background-color: transparent;
	border-color: #39f56a;
	color: #39f56a;
}

.sr-bb .srt-base-1-primary-8 {
	background-color: transparent;
	border-color: #39f56a;
	color: #39f56a;
}

.sr-bb .srt-base-1-primary-9 {
	background-color: transparent;
	border-color: #3b784a;
	color: #3b784a;
}

.sr-bb .srt-base-1-primary-10 {
	background-color: transparent;
	border-color: #3ad161;
	color: #3ad161;
}

.sr-bb .srt-base-1-primary-11 {
	background-color: transparent;
	border-color: #33dd5f;
	color: #33dd5f;
}

.sr-bb .srt-base-1-primary-13 {
	background-color: #292f2a;
	border-color: rgba(57, 245, 106, 30%);
	color: #3c4b3e;
}

.sr-bb .srt-base-1-neutral-1 {
	background-color: transparent;
	border-color: #555a55;
	color: #555a55;
}

.sr-bb .srt-base-1-neutral-2 {
	background-color: transparent;
	border-color: #e6e7e6;
	color: #e6e7e6;
}

.sr-bb .srt-base-1-neutral-3 {
	background-color: transparent;
	border-color: rgba(249, 249, 249, 12%);
	color: rgba(249, 249, 249, 12%);
}

.sr-bb .srt-base-1-neutral-4 {
	background-color: transparent;
	border-color: #b7b9b7;
	color: #b7b9b7;
}

.sr-bb .srt-base-1-neutral-5 {
	background-color: transparent;
	border-color: #cacbca;
	color: #cacbca;
}

.sr-bb .srt-base-1-neutral-6 {
	background-color: transparent;
	border-color: #696e69;
	color: #696e69;
}

.sr-bb .srt-base-1-neutral-7 {
	background-color: transparent;
	border-color: #b7b9b7;
	color: #b7b9b7;
}

.sr-bb .srt-base-1-neutral-8 {
	background-color: transparent;
	border-color: #555a55;
	color: #555a55;
}

.sr-bb .srt-base-1-neutral-9 {
	background-color: transparent;
	border-color: #474d47;
	color: #474d47;
}

.sr-bb .srt-base-1-neutral-10 {
	background-color: transparent;
	border-color: #535853;
	color: #535853;
}

.sr-bb .srt-base-1-neutral-11 {
	background-color: transparent;
	border-color: #888b88;
	color: #888b88;
}

.sr-bb .srt-base-1-neutral-12 {
	background-color: transparent;
	border-color: #c0c2c0;
	color: #c0c2c0;
}

.sr-bb .srt-base-1-neutral-13 {
	background-color: transparent;
	border-color: #4f544f;
	color: #4f544f;
}

.sr-bb .srt-base-1-is-active-primary {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #39f56a;
	color: #39f56a;
}

.sr-bb .srt-base-1-is-active-home-1 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #c0f9c8;
	color: #c0f9c8;
}

.sr-bb .srt-base-1-is-active-away-1 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #f9f9f9;
	color: #f9f9f9;
}

.sr-bb .srt-base-1-is-active-home-2 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #8af7a6;
	color: #c0f9c8;
}

.sr-bb .srt-base-1-is-active-away-2 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #8af7a6;
	color: #f9f9f9;
}

.sr-bb .srt-base-1-is-active-home-3 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: rgba(249, 249, 249, 12%);
	color: #c0f9c8;
}

.sr-bb .srt-base-1-is-active-away-3 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-base-1-is-active-home-4 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #98c29e;
	color: #98c29e;
}

.sr-bb .srt-base-1-is-active-away-4 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #3b3fc5;
	color: #3b3fc5;
}

.sr-bb .srt-base-1-is-active-home-5 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #d4f9d9;
	color: #d4f9d9;
}

.sr-bb .srt-base-1-is-active-away-5 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #7d7ffd;
	color: #7d7ffd;
}

.sr-bb .srt-base-1-is-active-primary-1 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #39f56a;
	color: #39f56a;
}

.sr-bb .srt-base-1-is-active-primary-2 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #73f695;
	color: #73f695;
}

.sr-bb .srt-base-1-is-active-primary-3 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #33dd5f;
	color: #33dd5f;
}

.sr-bb .srt-base-1-is-active-primary-4 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #3aad58;
	color: #3aad58;
}

.sr-bb .srt-base-1-is-active-primary-5 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #3aad58;
	color: #3aad58;
}

.sr-bb .srt-base-1-is-active-primary-6 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #8af7a6;
	color: #8af7a6;
}

.sr-bb .srt-base-1-is-active-primary-7 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #39f56a;
	color: #39f56a;
}

.sr-bb .srt-base-1-is-active-primary-8 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #39f56a;
	color: #39f56a;
}

.sr-bb .srt-base-1-is-active-primary-9 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #3b784a;
	color: #3b784a;
}

.sr-bb .srt-base-1-is-active-primary-10 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #3ad161;
	color: #3ad161;
}

.sr-bb .srt-base-1-is-active-primary-11 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #33dd5f;
	color: #33dd5f;
}

.sr-bb .srt-base-1-is-active-neutral-1 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #555a55;
	color: #555a55;
}

.sr-bb .srt-base-1-is-active-neutral-2 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #e6e7e6;
	color: #e6e7e6;
}

.sr-bb .srt-base-1-is-active-neutral-3 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: rgba(249, 249, 249, 12%);
	color: rgba(249, 249, 249, 12%);
}

.sr-bb .srt-base-1-is-active-neutral-4 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #b7b9b7;
	color: #b7b9b7;
}

.sr-bb .srt-base-1-is-active-neutral-5 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #cacbca;
	color: #cacbca;
}

.sr-bb .srt-base-1-is-active-neutral-6 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #696e69;
	color: #696e69;
}

.sr-bb .srt-base-1-is-active-neutral-7 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #b7b9b7;
	color: #b7b9b7;
}

.sr-bb .srt-base-1-is-active-neutral-8 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #555a55;
	color: #555a55;
}

.sr-bb .srt-base-1-is-active-neutral-9 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #474d47;
	color: #474d47;
}

.sr-bb .srt-base-1-is-active-neutral-10 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #535853;
	color: #535853;
}

.sr-bb .srt-base-1-is-active-neutral-11 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #888b88;
	color: #888b88;
}

.sr-bb .srt-base-1-is-active-neutral-12 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #c0c2c0;
	color: #c0c2c0;
}

.sr-bb .srt-base-1-is-active-neutral-13 {
	background-color: rgba(57, 245, 106, 12%);
	border-color: #4f544f;
	color: #4f544f;
}

.sr-bb .srt-home-1 {
	background-color: #c0f9c8;
	border-color: #c0f9c8;
	color: #000;
}

.sr-bb .srt-away-1 {
	background-color: #f9f9f9;
	border-color: #f9f9f9;
	color: #fff;
}

.sr-bb .srt-home-2 {
	background-color: #c0f9c8;
	border-color: #8af7a6;
	color: #000;
}

.sr-bb .srt-away-2 {
	background-color: #f9f9f9;
	border-color: #8af7a6;
	color: #fff;
}

.sr-bb .srt-home-3 {
	background-color: #c0f9c8;
	border-color: #292f2a;
	color: #000;
}

.sr-bb .srt-away-3 {
	background-color: #f9f9f9;
	border-color: #292f2a;
	color: #fff;
}

.sr-bb .srt-home-4 {
	background-color: #98c29e;
	border-color: #98c29e;
	color: #000;
}

.sr-bb .srt-away-4 {
	background-color: #3b3fc5;
	border-color: #3b3fc5;
	color: #fff;
}

.sr-bb .srt-home-5 {
	background-color: #d4f9d9;
	border-color: #d4f9d9;
	color: #000;
}

.sr-bb .srt-away-5 {
	background-color: #7d7ffd;
	border-color: #7d7ffd;
	color: #fff;
}

.sr-bb .srt-home-6 {
	background-color: rgba(192, 249, 200, 20%);
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-away-6 {
	background-color: rgba(58, 61, 255, 20%);
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-home-6-is-hoverable:hover {
	background-color: rgba(192, 249, 200, 20%);
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-away-6-is-hoverable:hover {
	background-color: rgba(58, 61, 255, 20%);
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-neutral-1 {
	background-color: #555a55;
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-neutral-2 {
	background-color: #e6e7e6;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-neutral-3 {
	background-color: rgba(249, 249, 249, 12%);
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-neutral-4 {
	background-color: #b7b9b7;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-neutral-5 {
	background-color: #cacbca;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-neutral-6 {
	background-color: #696e69;
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-neutral-7 {
	background-color: #b7b9b7;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-neutral-8 {
	background-color: #555a55;
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-neutral-9 {
	background-color: #474d47;
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-neutral-10 {
	background-color: #535853;
	border-color: rgba(249, 249, 249, 12%);
	color: rgba(249, 249, 249, 82%);
}

.sr-bb .srt-neutral-11 {
	background-color: #888b88;
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-neutral-12 {
	background-color: #c0c2c0;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-neutral-13 {
	background-color: #4f544f;
	border-color: rgba(249, 249, 249, 12%);
	color: #f9f9f9;
}

.sr-bb .srt-win {
	background-color: #c0f9c8;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-draw {
	background-color: rgba(249, 249, 249, 40%);
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-lose {
	background-color: #f9f9f9;
	border-color: rgba(0, 0, 0, 16%);
	color: #fff;
}

.sr-bb .srt-text-secondary {
	-webkit-font-smoothing: antialiased;
	opacity: 0.8;
}

.sr-bb .srt-text-disabled {
	opacity: 0.6;
}

.sr-bb .srt-text-tertiary {
	opacity: 0.7;
}

.sr-bb .srt-primary-1 .srt-text-secondary,
.sr-bb .srt-primary-1.srt-text-secondary,
.sr-bb .srt-primary-1-is-active .srt-text-secondary,
.sr-bb .srt-primary-1-is-active.srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled .srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled.srt-text-secondary,
.sr-bb .srt-primary-2 .srt-text-secondary,
.sr-bb .srt-primary-2.srt-text-secondary,
.sr-bb .srt-primary-3 .srt-text-secondary,
.sr-bb .srt-primary-3.srt-text-secondary,
.sr-bb .srt-primary-4 .srt-text-secondary,
.sr-bb .srt-primary-4.srt-text-secondary,
.sr-bb .srt-primary-5 .srt-text-secondary,
.sr-bb .srt-primary-5.srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-6 .srt-text-secondary,
.sr-bb .srt-primary-6.srt-text-secondary,
.sr-bb .srt-primary-7 .srt-text-secondary,
.sr-bb .srt-primary-7.srt-text-secondary,
.sr-bb .srt-primary-8 .srt-text-secondary,
.sr-bb .srt-primary-8.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2.srt-text-secondary,
.sr-bb .srt-primary-9 .srt-text-secondary,
.sr-bb .srt-primary-9.srt-text-secondary,
.sr-bb .srt-primary-10 .srt-text-secondary,
.sr-bb .srt-primary-10.srt-text-secondary,
.sr-bb .srt-primary-11 .srt-text-secondary,
.sr-bb .srt-primary-11.srt-text-secondary,
.sr-bb .srt-primary-12 .srt-text-secondary,
.sr-bb .srt-primary-12.srt-text-secondary,
.sr-bb .srt-primary-13 .srt-text-secondary,
.sr-bb .srt-primary-13.srt-text-secondary {
	-webkit-font-smoothing: antialiased;
	opacity: 0.7;
}

.sr-bb .srt-primary-1 .srt-text-disabled,
.sr-bb .srt-primary-1.srt-text-disabled,
.sr-bb .srt-primary-1-is-active .srt-text-disabled,
.sr-bb .srt-primary-1-is-active.srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled .srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled.srt-text-disabled,
.sr-bb .srt-primary-2 .srt-text-disabled,
.sr-bb .srt-primary-2.srt-text-disabled,
.sr-bb .srt-primary-3 .srt-text-disabled,
.sr-bb .srt-primary-3.srt-text-disabled,
.sr-bb .srt-primary-4 .srt-text-disabled,
.sr-bb .srt-primary-4.srt-text-disabled,
.sr-bb .srt-primary-5 .srt-text-disabled,
.sr-bb .srt-primary-5.srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-6 .srt-text-disabled,
.sr-bb .srt-primary-6.srt-text-disabled,
.sr-bb .srt-primary-7 .srt-text-disabled,
.sr-bb .srt-primary-7.srt-text-disabled,
.sr-bb .srt-primary-8 .srt-text-disabled,
.sr-bb .srt-primary-8.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2.srt-text-disabled,
.sr-bb .srt-primary-9 .srt-text-disabled,
.sr-bb .srt-primary-9.srt-text-disabled,
.sr-bb .srt-primary-10 .srt-text-disabled,
.sr-bb .srt-primary-10.srt-text-disabled,
.sr-bb .srt-primary-11 .srt-text-disabled,
.sr-bb .srt-primary-11.srt-text-disabled,
.sr-bb .srt-primary-12 .srt-text-disabled,
.sr-bb .srt-primary-12.srt-text-disabled,
.sr-bb .srt-primary-13 .srt-text-disabled,
.sr-bb .srt-primary-13.srt-text-disabled {
	opacity: 0.35;
}

.sr-bb .srt-primary-1 .srt-text-tertiary,
.sr-bb .srt-primary-1.srt-text-tertiary,
.sr-bb .srt-primary-1-is-active .srt-text-tertiary,
.sr-bb .srt-primary-1-is-active.srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled .srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled.srt-text-tertiary,
.sr-bb .srt-primary-2 .srt-text-tertiary,
.sr-bb .srt-primary-2.srt-text-tertiary,
.sr-bb .srt-primary-3 .srt-text-tertiary,
.sr-bb .srt-primary-3.srt-text-tertiary,
.sr-bb .srt-primary-4 .srt-text-tertiary,
.sr-bb .srt-primary-4.srt-text-tertiary,
.sr-bb .srt-primary-5 .srt-text-tertiary,
.sr-bb .srt-primary-5.srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-6 .srt-text-tertiary,
.sr-bb .srt-primary-6.srt-text-tertiary,
.sr-bb .srt-primary-7 .srt-text-tertiary,
.sr-bb .srt-primary-7.srt-text-tertiary,
.sr-bb .srt-primary-8 .srt-text-tertiary,
.sr-bb .srt-primary-8.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2.srt-text-tertiary,
.sr-bb .srt-primary-9 .srt-text-tertiary,
.sr-bb .srt-primary-9.srt-text-tertiary,
.sr-bb .srt-primary-10 .srt-text-tertiary,
.sr-bb .srt-primary-10.srt-text-tertiary,
.sr-bb .srt-primary-11 .srt-text-tertiary,
.sr-bb .srt-primary-11.srt-text-tertiary,
.sr-bb .srt-primary-12 .srt-text-tertiary,
.sr-bb .srt-primary-12.srt-text-tertiary,
.sr-bb .srt-primary-13 .srt-text-tertiary,
.sr-bb .srt-primary-13.srt-text-tertiary {
	opacity: 0.54;
}

.sr-bb .srt-icon {
	opacity: 0.33;
}

.sr-bb .srt-icon-secondary {
	opacity: 0.7;
}

.sr-bb .srt-elevation-1 {
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 20%), 0 1px 3px 0 rgba(0, 0, 0, 10%);
}

.sr-bb .srt-elevation-2 {
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 23%), 0 3px 6px 0 rgba(0, 0, 0, 16%);
}

.sr-bb .srt-elevation-3 {
	box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 26%), 0 10px 20px 0 rgba(0, 0, 0, 19%);
}

.sr-bb .srt-elevation-center-2 {
	box-shadow: 0 1px 5px rgba(0, 0, 0, 23%), 0 1px 5px rgba(0, 0, 0, 16%);
}

.sr-bb .srt-inset-top-1 {
	box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 23%);
}

.sr-bb .srt-inset-bottom-1 {
	box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 23%);
}

.sr-bb .srt-inset-top-2 {
	box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 23%);
}

.sr-bb .srt-inset-bottom-2 {
	box-shadow: inset 0 -3px 6px 0 rgba(0, 0, 0, 23%);
}

.sr-bb .srt-inset-top-3 {
	box-shadow: inset 0 6px 6px 0 rgba(0, 0, 0, 23%);
}

.sr-bb .srt-inset-bottom-3 {
	box-shadow: inset 0 -6px 6px 0 rgba(0, 0, 0, 23%);
}

.sr-bb .srt-fill-blue-card {
	fill: #2579ad;
}

.sr-bb .srt-stroke-blue-card {
	stroke: #2579ad;
}

.sr-bb .srt-fill-green-card {
	fill: #2cbd00;
}

.sr-bb .srt-stroke-green-card {
	stroke: #2cbd00;
}

.sr-bb .srt-fill-soccer-yellow-card {
	fill: #ffbf00;
}

.sr-bb .srt-stroke-soccer-yellow-card {
	stroke: #ffbf00;
}

.sr-bb .srt-fill-soccer-red-card {
	fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-red-card {
	stroke: #e43b3b;
}

.sr-bb .srt-stroke-soccer-substitution-in {
	fill: transparent;
	stroke: #4fbe30;
}

.sr-bb .srt-fill-soccer-substitution-in {
	fill: #4fbe30;
}

.sr-bb .srt-stroke-soccer-substitution-out {
	fill: transparent;
	stroke: #e43b3b;
}

.sr-bb .srt-fill-soccer-substitution-out {
	fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-own-goal {
	fill: transparent;
	stroke: #e43b3b;
}

.sr-bb .srt-fill-soccer-own-goal {
	fill: #e43b3b;
}

.sr-bb .srt-fill-soccer-relegation-1 {
	fill: #fdd835;
}

.sr-bb .srt-stroke-soccer-relegation-1 {
	stroke: #fdd835;
}

.sr-bb .srt-fill-soccer-relegation-2 {
	fill: #ffb848;
}

.sr-bb .srt-stroke-soccer-relegation-2 {
	stroke: #ffb848;
}

.sr-bb .srt-fill-soccer-relegation-3 {
	fill: #ef6c00;
}

.sr-bb .srt-stroke-soccer-relegation-3 {
	stroke: #ef6c00;
}

.sr-bb .srt-fill-soccer-relegation-4 {
	fill: #e93a34;
}

.sr-bb .srt-stroke-soccer-relegation-4 {
	stroke: #e93a34;
}

.sr-bb .srt-fill-soccer-relegation-5 {
	fill: #941d1d;
}

.sr-bb .srt-stroke-soccer-relegation-5 {
	stroke: #941d1d;
}

.sr-bb .srt-fill-soccer-promotion-1 {
	fill: #51d151;
}

.sr-bb .srt-stroke-soccer-promotion-1 {
	stroke: #51d151;
}

.sr-bb .srt-fill-soccer-promotion-2 {
	fill: #1b911b;
}

.sr-bb .srt-stroke-soccer-promotion-2 {
	stroke: #1b911b;
}

.sr-bb .srt-fill-soccer-promotion-3 {
	fill: #0e8094;
}

.sr-bb .srt-stroke-soccer-promotion-3 {
	stroke: #0e8094;
}

.sr-bb .srt-fill-soccer-promotion-4 {
	fill: #0a6cce;
}

.sr-bb .srt-stroke-soccer-promotion-4 {
	stroke: #0a6cce;
}

.sr-bb .srt-fill-soccer-promotion-5 {
	fill: #4a9fe4;
}

.sr-bb .srt-stroke-soccer-promotion-5 {
	stroke: #4a9fe4;
}

.sr-bb .srt-nfl-timeout-1 {
	background-color: #f5a623;
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-nfl-timeout-2 {
	background-color: rgba(245, 166, 35, 30%);
	border-color: rgba(0, 0, 0, 16%);
	color: #000;
}

.sr-bb .srt-nfl-penalty {
	background-color: #f4a621;
	border-color: #f4a621;
	color: #000;
}

.sr-bb .srt-nfl-negative-yards-1 {
	background-color: #ac182e;
	border-color: #ac182e;
	color: #000;
}

.sr-bb .srt-nfl-negative-yards-2 {
	background-color: #ac182e;
	border-color: #ac182e;
	color: #000;
}

.sr-bb .srt-nfl-first-line {
	background-color: #4b90de;
	border-color: #4b90de;
	color: #000;
}

.sr-bb .srt-nfl-ten-line {
	background-color: #f5a623;
	border-color: #f5a623;
	color: #000;
}

.sr-bb .srt-fill-nfl-penalty {
	fill: #f4a621;
}

.sr-bb .srt-stroke-nfl-penalty {
	stroke: #f4a621;
}

.sr-bb .srt-fill-nfl-negative-yards-1 {
	fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-1 {
	stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-negative-yards-2 {
	fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-2 {
	stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-first-line {
	fill: #4b90de;
}

.sr-bb .srt-stroke-nfl-first-line {
	stroke: #4b90de;
}

.sr-bb .srt-fill-nfl-ten-line {
	fill: #f5a623;
}

.sr-bb .srt-stroke-nfl-ten-line {
	stroke: #f5a623;
}

.sr-bb .srt-mlb-run-1 {
	background-color: #090;
	border-color: #090;
	color: #fff;
}

.sr-bb .srt-mlb-run-2 {
	background-color: #fff;
	border-color: #090;
	color: #090;
}

.sr-bb .srt-mlb-hit-1 {
	background-color: #f7ab11;
	border-color: #f7ab11;
	color: #f9f9f9;
}

.sr-bb .srt-mlb-hit-2 {
	background-color: #fff;
	border-color: #f7ab11;
	color: #f7ab11;
}

.sr-bb .srt-mlb-hit-3 {
	background-color: #00a4ff;
	border-color: #00a4ff;
	color: #fff;
}

.sr-bb .srt-mlb-error-1 {
	background-color: #d0021b;
	border-color: #d0021b;
	color: #fff;
}

.sr-bb .srt-mlb-error-2 {
	background-color: #fff;
	border-color: #d0021b;
	color: #d0021b;
}

.sr-bb .srt-mlb-neutral-1 {
	background-color: #212121;
	border-color: #fff;
	color: #fff;
}

.sr-bb .srt-fill-mlb-base {
	fill: #00a4ff;
}

.sr-bb .srt-strole-mlb-base {
	fill: #00a4ff;
}

.sr-bb .srt-fill-change-increase {
	fill: #c0f9c8;
}

.sr-bb .srt-stroke-change-increase {
	stroke: #c0f9c8;
}

.sr-bb .srt-fill-change-decrease {
	fill: #f9f9f9;
}

.sr-bb .srt-stroke-change-decrease {
	stroke: #f9f9f9;
}

.sr-bb .srt-fill-text {
	fill: #f9f9f9;
}

.sr-bb .srt-fill-text-invert {
	fill: #000;
}

.sr-bb .srt-fill-text-secondary {
	fill-opacity: 0.8;
}

.sr-bb .srt-fill-text-disabled {
	fill-opacity: 0.6;
}

.sr-bb .srt-fill-base-1 {
	fill: transparent;
}

.sr-bb .srt-stroke-base-1 {
	stroke: transparent;
}

.sr-bb .srt-fill-base-1-active {
	fill: rgba(57, 245, 106, 12%);
}

.sr-bb .srt-stroke-base-1-active {
	stroke: rgba(57, 245, 106, 12%);
}

.sr-bb .srt-fill-base-1-active-2 {
	fill: #4f544f;
}

.sr-bb .srt-stroke-base-1-active-2 {
	stroke: #4f544f;
}

.sr-bb .srt-fill-base-1-primary {
	fill: transparent;
}

.sr-bb .srt-stroke-base-1-primary {
	stroke: transparent;
}

.sr-bb .srt-fill-base-1-home {
	fill: transparent;
}

.sr-bb .srt-stroke-base-1-home {
	stroke: transparent;
}

.sr-bb .srt-fill-base-1-away {
	fill: transparent;
}

.sr-bb .srt-stroke-base-1-away {
	stroke: transparent;
}

.sr-bb .srt-fill-base-2 {
	fill: currentcolor;
}

.sr-bb .srt-stroke-base-2 {
	stroke: currentcolor;
}

.sr-bb .srt-fill-base-2-active {
	fill: #3c5742;
}

.sr-bb .srt-stroke-base-2-active {
	stroke: #3c5742;
}

.sr-bb .srt-fill-base-2-hover {
	fill: #3c5742;
}

.sr-bb .srt-stroke-base-2-hover {
	stroke: #3c5742;
}

.sr-bb .srt-fill-base-3 {
	fill: #3c5742;
}

.sr-bb .srt-stroke-base-3 {
	stroke: #3c5742;
}

.sr-bb .srt-fill-base-3-active {
	fill: #3b6044;
}

.sr-bb .srt-stroke-base-3-active {
	stroke: #3b6044;
}

.sr-bb .srt-fill-base-3-hover {
	fill: #3b6044;
}

.sr-bb .srt-stroke-base-3-hover {
	stroke: #3b6044;
}

.sr-bb .srt-fill-primary-1 {
	fill: #39f56a;
}

.sr-bb .srt-stroke-primary-1 {
	stroke: #39f56a;
}

.sr-bb .srt-fill-primary-2 {
	fill: #73f695;
}

.sr-bb .srt-stroke-primary-2 {
	stroke: #73f695;
}

.sr-bb .srt-fill-primary-3 {
	fill: #33dd5f;
}

.sr-bb .srt-stroke-primary-3 {
	stroke: #33dd5f;
}

.sr-bb .srt-fill-primary-4 {
	fill: #3aad58;
}

.sr-bb .srt-stroke-primary-4 {
	stroke: #3aad58;
}

.sr-bb .srt-fill-primary-5 {
	fill: #3aad58;
}

.sr-bb .srt-stroke-primary-5 {
	stroke: #3aad58;
}

.sr-bb .srt-fill-primary-6 {
	fill: #8af7a6;
}

.sr-bb .srt-stroke-primary-6 {
	stroke: #8af7a6;
}

.sr-bb .srt-fill-primary-7 {
	fill: #39f56a;
}

.sr-bb .srt-stroke-primary-7 {
	stroke: #39f56a;
}

.sr-bb .srt-fill-primary-8 {
	fill: #39f56a;
}

.sr-bb .srt-stroke-primary-8 {
	stroke: #39f56a;
}

.sr-bb .srt-fill-primary-8-is-active-1 {
	fill: #33dd5f;
}

.sr-bb .srt-stroke-primary-8-is-active-1 {
	stroke: #33dd5f;
}

.sr-bb .srt-fill-primary-8-is-active-2 {
	fill: #33dd5f;
}

.sr-bb .srt-stroke-primary-8-is-active-2 {
	stroke: #33dd5f;
}

.sr-bb .srt-fill-primary-9 {
	fill: #3b784a;
}

.sr-bb .srt-stroke-primary-9 {
	stroke: #3b784a;
}

.sr-bb .srt-fill-primary-10 {
	fill: #3ad161;
}

.sr-bb .srt-stroke-primary-10 {
	stroke: #3ad161;
}

.sr-bb .srt-fill-primary-11 {
	fill: #33dd5f;
}

.sr-bb .srt-stroke-primary-11 {
	stroke: #33dd5f;
}

.sr-bb .srt-fill-primary-12 {
	fill: #39f56a;
}

.sr-bb .srt-stroke-primary-12 {
	stroke: #39f56a;
}

.sr-bb .srt-fill-home-1 {
	fill: #c0f9c8;
}

.sr-bb .srt-stroke-home-1 {
	stroke: #c0f9c8;
}

.sr-bb .srt-fill-home-2 {
	fill: #c0f9c8;
}

.sr-bb .srt-stroke-home-2 {
	stroke: #c0f9c8;
}

.sr-bb .srt-fill-home-3 {
	fill: #c0f9c8;
}

.sr-bb .srt-stroke-home-3 {
	stroke: #c0f9c8;
}

.sr-bb .srt-fill-home-4 {
	fill: #98c29e;
}

.sr-bb .srt-stroke-home-4 {
	stroke: #98c29e;
}

.sr-bb .srt-fill-home-5 {
	fill: #d4f9d9;
}

.sr-bb .srt-stroke-home-5 {
	stroke: #d4f9d9;
}

.sr-bb .srt-fill-away-1 {
	fill: #f9f9f9;
}

.sr-bb .srt-stroke-away-1 {
	stroke: #f9f9f9;
}

.sr-bb .srt-fill-away-2 {
	fill: #f9f9f9;
}

.sr-bb .srt-stroke-away-2 {
	stroke: #f9f9f9;
}

.sr-bb .srt-fill-away-3 {
	fill: #f9f9f9;
}

.sr-bb .srt-stroke-away-3 {
	stroke: #f9f9f9;
}

.sr-bb .srt-fill-away-4 {
	fill: #3b3fc5;
}

.sr-bb .srt-stroke-away-4 {
	stroke: #3b3fc5;
}

.sr-bb .srt-fill-away-5 {
	fill: #7d7ffd;
}

.sr-bb .srt-stroke-away-5 {
	stroke: #7d7ffd;
}

.sr-bb .srt-fill-neutral-1 {
	fill: #555a55;
}

.sr-bb .srt-stroke-neutral-1 {
	stroke: #555a55;
}

.sr-bb .srt-fill-neutral-2 {
	fill: #e6e7e6;
}

.sr-bb .srt-stroke-neutral-2 {
	stroke: #e6e7e6;
}

.sr-bb .srt-fill-neutral-3 {
	fill: rgba(249, 249, 249, 12%);
}

.sr-bb .srt-stroke-neutral-3 {
	stroke: rgba(249, 249, 249, 12%);
}

.sr-bb .srt-fill-neutral-4 {
	fill: #b7b9b7;
}

.sr-bb .srt-stroke-neutral-4 {
	stroke: #b7b9b7;
}

.sr-bb .srt-fill-neutral-5 {
	fill: #cacbca;
}

.sr-bb .srt-stroke-neutral-5 {
	stroke: #cacbca;
}

.sr-bb .srt-fill-neutral-6 {
	fill: #696e69;
}

.sr-bb .srt-stroke-neutral-6 {
	stroke: #696e69;
}

.sr-bb .srt-fill-neutral-7 {
	fill: #b7b9b7;
}

.sr-bb .srt-stroke-neutral-7 {
	stroke: #b7b9b7;
}

.sr-bb .srt-fill-neutral-8 {
	fill: #555a55;
}

.sr-bb .srt-stroke-neutral-8 {
	stroke: #555a55;
}

.sr-bb .srt-fill-neutral-9 {
	fill: #474d47;
}

.sr-bb .srt-stroke-neutral-9 {
	stroke: #474d47;
}

.sr-bb .srt-fill-neutral-10 {
	fill: #535853;
}

.sr-bb .srt-stroke-neutral-10 {
	stroke: #535853;
}

.sr-bb .srt-fill-neutral-11 {
	fill: #888b88;
}

.sr-bb .srt-stroke-neutral-11 {
	stroke: #888b88;
}

.sr-bb .srt-fill-neutral-12 {
	fill: #c0c2c0;
}

.sr-bb .srt-stroke-neutral-12 {
	stroke: #c0c2c0;
}

.sr-bb .srt-fill-neutral-13 {
	fill: #4f544f;
}

.sr-bb .srt-stroke-neutral-13 {
	stroke: #4f544f;
}

.sr-bb .srt-fill-win {
	fill: #c0f9c8;
}

.sr-bb .srt-stroke-win {
	stroke: #c0f9c8;
}

.sr-bb .srt-fill-draw {
	fill: rgba(249, 249, 249, 40%);
}

.sr-bb .srt-stroke-draw {
	stroke: rgba(249, 249, 249, 40%);
}

.sr-bb .srt-fill-lose {
	fill: #f9f9f9;
}

.sr-bb .srt-stroke-lose {
	stroke: #f9f9f9;
}

.sr-bb .srt-stop-base-1 {
	stop-color: transparent;
}

.sr-bb .srt-stop-primary-1 {
	stop-color: #39f56a;
}

.sr-bb .srt-stop-primary-2 {
	stop-color: #73f695;
}

.sr-bb .srt-stop-primary-3 {
	stop-color: #33dd5f;
}

.sr-bb .srt-stop-primary-4 {
	stop-color: #3aad58;
}

.sr-bb .srt-stop-primary-5 {
	stop-color: #3aad58;
}

.sr-bb .srt-stop-primary-6 {
	stop-color: #8af7a6;
}

.sr-bb .srt-stop-primary-7 {
	stop-color: #39f56a;
}

.sr-bb .srt-stop-primary-8 {
	stop-color: #39f56a;
}

.sr-bb .srt-stop-primary-9 {
	stop-color: #3b784a;
}

.sr-bb .srt-stop-primary-10 {
	stop-color: #3ad161;
}

.sr-bb .srt-stop-primary-11 {
	stop-color: #33dd5f;
}

.sr-bb .srt-stop-primary-12 {
	stop-color: #39f56a;
}

.sr-bb .srt-stop-home-1 {
	stop-color: #c0f9c8;
}

.sr-bb .srt-stop-away-1 {
	stop-color: #f9f9f9;
}

.sr-bb .srt-fill-neutral-14 {
	fill: #292f2a;
}

.sr-bb .srt-stroke-neutral-14 {
	stroke: #292f2a;
}

.sr-bb .srt-logo-powered-by-light {
	display: none;
}

.sr-bb .srt-logo-powered-by-dark {
	display: inline-block;
}
