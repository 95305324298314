@use 'custom-properties';
@use 'semantic-variables';
@use 'sportsbook';

// base/type

body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
label,
legend,
button,
input,
select,
textarea,
li,
a,
strong,
span,
th,
caption,
td,
p,
footer,
small {
	color: rgba(var(--font-light), 1);
	font-family: 'Open Sauce One', sans-serif;
}

body {
	background-color: rgba(var(--base-10), 1);
	// TODO find a better way to do the uppercase for text using Gravity font
	h1,
	h2 {
		letter-spacing: 0.02em;
		text-transform: uppercase;
	}

	&.access-blocked {
		background-color: rgba(var(--primary-accent-grey), 1);

		h2 {
			color: rgba(var(--font-light), 1);
			font: var(--h2);
		}

		p {
			color: rgba(var(--font-supportive), 1);
		}

		a {
			color: rgba(var(--primary-accent-green), 1);
		}
	}
}

/* Add a thumb */
.desktop-scrollbar > div::-webkit-scrollbar-thumb {
	background: #9fa2ab;
}

small {
	color: rgba(var(--font-supportive), 1);
}

// components/bonus-active

.progress {
	svg {
		path {
			stroke: rgba(var(--base-11), 1);
		}

		.progress__meter {
			stroke: rgba(var(--primary-accent-green), 1);
		}
	}
}

// components/buttons

.btn {
	background-color: rgba(var(--primary-accent-green), 1);
	color: rgba(var(--font-dark), 1);

	&:disabled {
		background-color: rgba(var(--primary-accent-grey-light), 1);
		color: rgba(var(--font-supportive), 1);
	}

	&.btn--secondary {
		background-color: rgba(var(--base-10), 1);
		color: rgba(var(--font-brand-01), 1);

		&:disabled {
			color: rgba(var(--base-12), 1);
		}
	}

	&.btn--tertiary {
		background: none;
		color: rgba(var(--font-light), 1);

		&:disabled {
			color: rgba(var(--base-12), 1);
		}
	}

	&.btn--logout {
		border-radius: 3px;
		color: rgba(var(--base-01), 1);
	}

	&.btn--primary-inverted {
		background-color: transparent;
		border: 1px solid rgba(var(--primary-accent-green), 1);

		* {
			color: rgba(var(--primary-accent-green), 1);
		}

		&.hide-icons svg {
			visibility: hidden;
		}
	}

	&.btn--clear,
	&.btn--login,
	&.btn--signup,
	&.btn--skip {
		color: rgba(var(--primary-accent-green), 1);
	}

	&.btn--applepay {
		-apple-pay-button-style: rgba(var(--base-01), 1);
	}

	&.btn--pag {
		background-color: rgba(var(--base-11), 1);
	}

	&.btn--link {
		color: rgba(var(--font-brand-01), 1);
	}

	&.btn--small {
		background-color: rgba(var(--base-09), 1);
	}

	&.btn--light {
		background-color: rgba(var(--primary-accent-green), 1);
	}

	&.btn--visit {
		background-color: rgba(var(--base-10), 1);
		color: rgba(var(--active-players-color), 1);
	}

	&.btn--small.btn--clip-card {
		background-color: transparent;
		border: 1px solid rgba(var(--primary-accent-green), 1);
		color: rgba(var(--primary-accent-green), 1);
	}

	.btn--wespin {
		background-color: rgba(var(--primary-accent-green), 1);
		border: 0;
		color: rgba(var(--font-light), 1);
	}

	&.btn--tutorial-card-button {
		background-color: transparent;
		block-size: fit-content;
		border: 0;
		color: rgba(var(--base-01), 1);
		margin: 14px 0;
		padding: 0;
		text-decoration: underline;
		width: fit-content !important;
	}

	&.btn-accept {
		color: rgba(var(--base-11), 1);
	}

	&.btn--ghost {
		color: rgba(var(--font-light), 1);
	}

	&.btn--action-screen-confirm {
		background-color: rgba(var(--primary-accent-green), 1);
		color: rgba(var(--font-dark), 1);
	}

	&.btn--responsible-gaming-settings {
		background-color: rgba(var(--primary-accent-green), 1);
	}

	&.btn--responsible-gaming-logout {
		background-color: transparent;
		color: rgba(var(--primary-accent-green), 1);
	}

	&.btn--transaction-history-reality-check,
	&.btn--disable-reality-check {
		background-color: transparent;
		border: solid 1px rgba(var(--primary-accent-green), 1);
		color: rgba(var(--primary-accent-green), 1);
	}
}

// components/cc-form

.cc-form,
li.deposit-limit {
	&:focus-within .active-border {
		border-bottom: 2px solid rgba(var(--primary), 1);
	}
}

// components/collapse

.welcome-menu {
	.info-box {
		background-color: rgba(var(--base-01), 1);

		.info-box-text,
		.info-box-value {
			color: rgba(var(--base-09), 1);
		}
	}

	.link-list {
		.at-transaction-history {
			.link-list__continue-icon {
				fill: rgba(var(--base-01), 1);
			}
		}

		.at-my-limits {
			.link-list__continue-icon {
				fill: rgba(var(--base-01), 1);
			}
		}
	}

	.welcome-screen-collapse {
		background-color: rgba(var(--primary-accent-green), 1);

		.welcome-screen__summa-icon {
			fill: rgba(var(--base-01), 1);
		}

		p {
			color: rgba(var(--base-01), 1);
		}

		.collapse__continue-icon {
			fill: rgba(var(--base-01), 1);
		}

		tr:not(:last-of-type) {
			border-bottom: 1px solid rgba(var(--primary-accent-green-light), 1);
		}
	}
}

.collapse__continue-icon {
	fill: rgba(var(--base-01), 1);
}

// components/detail-accordion

.details-accordion {
	.summary-icon::after {
		filter: brightness(97%) sepia(0) hue-rotate(0deg) saturate(0);
	}
}

// components/filters

.user-options {
	background-color: var(--product-tab);
	border-bottom: 1px solid rgba(var(--primary-accent-grey-light), 1);
}

.filters {
	input {
		+ .filters__filter {
			background-color: rgba(var(--primary-accent-grey), 1);
		}

		&:checked {
			+ .filters__filter {
				color: rgba(var(--primary-accent-green), 1);
			}
		}
	}

	.sliding-border {
		background-color: rgba(var(--primary-accent-green), 1);
		height: 3px;
	}
}

.filters-container {
	svg {
		fill: rgba(var(--primary-accent-green-light), 1);
	}
}

.shop-product-filters {
	a {
		&::after {
			background-color: var(--filter-bar-inactive);
			bottom: 0;
			content: '';
			height: 1px;
			left: 0;
			position: absolute;
			width: 100%;
		}
	}
}

.user-options--lobby {
	background-color: var(--base-10);

	.filters {
		input {
			+ .filters__filter {
				background-color: rgba(var(--base-10), 1);
			}
		}
	}
}

// components/flags
// components/form

.form {
	.form__border {
		background-color: rgba(var(--primary-accent-grey-light), 1);

		&.form__border--error {
			background-color: rgba(var(--error), 1);
		}
	}

	input[type='text'],
	input[type='date'],
	input[type='time'],
	input[type='number'] {
		background-color: rgba(var(--base-10), 1);
		border-bottom: 1px solid rgba(var(--base-08), 1);
		color: rgba(var(--base-01), 1);

		+ label,
		+ legend {
			color: rgba(var(--base-06), 1);

			&.error {
				color: rgba(var(--error), 1);
			}
		}

		&:focus,
		&:hover {
			~ .form__border {
				background-color: rgba(var(--primary-accent-green), 1);
			}
		}

		&.input-amount {
			+ label {
				color: rgba(var(--base-01), 1);
			}
		}
	}

	.error {
		color: rgba(var(--error));
	}
}

:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus {
	box-shadow: inset 0 0 0 9999px #282b33 !important;
	-webkit-text-fill-color: #f9f9f9 !important;
}

::-webkit-calendar-picker-indicator {
	filter: invert(70%) sepia(55%) saturate(593%) hue-rotate(78deg) brightness(102%) contrast(95%);
}

.input-wrap {
	.input-wrap__input {
		background-color: rgba(var(--primary-accent-grey-dark), 1);
		border-bottom: 1px solid rgba(var(--primary-accent-grey-light), 1);
		color: rgba(var(--font-light), 1);

		&:focus {
			color: rgba(var(--primary-accent-green), 1);
		}
	}

	&::after {
		background-color: rgba(var(--primary-accent-green), 1);
	}

	.slider-input {
		background-color: rgba(var(--base-10), 1);
	}
}

.search-dropdown__input_container.input-wrap::after {
	background-color: inherit;
}

.slider-icon {
	.icon--sprite {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

.legend,
.input-wrap__label {
	color: rgba(var(--font-supportive), 1);
}

::-webkit-input-placeholder {
	color: rgba(var(--font-supportive), 1);
}

::-moz-placeholder {
	color: rgba(var(--font-supportive), 1);
}

:-ms-input-placeholder {
	color: rgba(var(--font-supportive), 1);
}

// New checkboxes

.custom-checkbox-list {
	li {
		border-bottom: 1px solid rgba(var(--base-07), 1);
	}
}

.toggle-password {
	svg {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

// components/game-item

.item__urgent {
	background-color: rgba(var(--primary-accent-pink-dark), 1);

	* {
		color: rgba(var(--base-01), 1);
	}
}

// components/game-list

.item__footer {
	border-top: 1px solid rgba(var(--base-10), 1);

	.item__love {
		border-right: 1px solid rgba(var(--base-10), 1);

		.item__love-icon {
			fill: rgba(var(--base-02), 1);
		}
	}
}

.item__love {
	&.game-item__love--selected {
		.item__love-icon {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}
}

.item__live-casino {
	border-bottom: 1px solid rgba(var(--base-09), 1);
}

.click-effect {
	&::after {
		background: rgba(var(--primary-accent-green), 0.25) !important;
	}
}

.game-item__jackpot {
	background-color: rgba(var(--base-02), 1);
	color: rgba(var(--base-12), 1);
}

.item__when {
	border-bottom: 1px solid rgba(var(--base-10), 1);
}

.item__footer {
	.item__balance-collected {
		border-left: 1px solid rgba(var(--base-10), 1);
	}
}

//product nav

.page-head {
	background-color: rgba(var(--primary-accent-grey), 1);

	.btn--login,
	.page-head__title {
		color: rgba(var(--font-light), 1);
		text-transform: none;
	}

	svg {
		fill: rgba(var(--primary-accent-green), 1);
	}

	&.page-head--we-spin {
		// background-color: var(--sidebar-bg);

		.page-head__title,
		.page-head__title span {
			color: rgba(var(--font-light), 1);
			text-transform: none;
		}

		.page-head__right-side p {
			color: rgba(var(--primary-accent-green), 1);
		}

		svg {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}

	&.u-bg-trans {
		&.page-head-welcome {
			svg {
				fill: rgba(var(--base-01), 1);
			}
		}
	}
}

// components/link-list
.link-list {
	.link-list__item {
		background-color: rgba(var(--base-10), 1);

		&--rounded {
			border-radius: 3px;
		}

		&.disabled {
			svg {
				fill: rgba(var(--base-08), 1);
			}

			.menu__main-title,
			.link-list__preamble,
			.link-list__continue-icon {
				color: rgba(var(--base-08), 1);
			}

			.link-list__continue-icon {
				fill: rgba(var(--base-02), 1);
			}
		}

		svg {
			fill: rgba(var(--primary-accent-green-light), 1);
		}

		.menu__main-title {
			color: rgba(var(--font-light), 1);
		}

		.link-list__preamble {
			color: rgba(var(--font-supportive), 1);
		}

		.link-list__continue-icon {
			fill: rgba(var(--primary-accent-green), 1);
		}

		.link-list__more-icon {
			height: 20px;
			width: 20px;
		}

		.link-list__value {
			color: rgba(var(--font-supportive), 1);
		}

		.link-list__status-message {
			color: rgba(var(--font-supportive), 1);
		}

		.link-list__status-verified {
			color: rgba(var(--primary-accent-green), 1);
		}

		.link-list__status-unverified {
			color: rgba(var(--base-05), 1);
		}

		.dots-container {
			svg {
				fill: rgba(var(--primary-accent-green), 1);
			}
		}
	}
}

.deposit-limit-link-list {
	.valid-limits-icon {
		svg {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}
}

.sportsbook-relieve-limits-dialog {
	.link-list {
		.link-list__item {
			.link-list__continue-icon {
				fill: rgba(var(--primary-accent-green), 1);
			}
		}
	}
}

// components/list-options

.list-options {
	background-color: rgba(var(--primary-accent-grey), 1);

	.link-list {
		.link-list__item {
			background-color: transparent;
			border-bottom: 1px solid rgba(var(--base-07), 1);

			.link-list__continue-icon {
				fill: rgba(var(--primary-accent-green), 1);
			}
		}
	}
}

// components/my notifications

.cta-button-container {
	background: rgba(var(--base-11), 1);
}

.my-notification-item-wrapper .my-notification-details .my-notification-title-bar .my-notification-title {
	color: rgba(var(--font-light), 1);
}

// components/notify

.notification-wrap {
	box-shadow: 0 0 4px black;
	color: rgba(var(--base-02), 1);

	.page-head__close {
		svg {
			fill: #fff;
		}
	}

	p,
	.notification-wrap__error-text-msg {
		color: rgba(var(--base-02), 1);
	}

	.notification-wrap__error-text-header {
		color: rgba(var(--base-02), 1);
	}

	.header-icon {
		svg {
			fill: #fff;
		}
	}

	&.red {
		background-color: rgba(var(--error), 1);
	}

	&.green {
		background-color: rgba(var(--success), 1);
	}

	&.neutral {
		background-color: rgba(var(--info), 1);
	}
}

//notification - inline
.notification-inline-wrap {
	&.red {
		background-color: rgba(var(--error), 1);
	}

	&.green {
		background-color: rgba(var(--success), 1);
	}

	&.neutral {
		background-color: rgba(var(--info), 1);
	}
}
// BINARY QUESTION MODAL

.binary-question {
	.binary-question__inner {
		background-color: rgba(var(--primary-accent-grey-light), 1);

		.binary-question__p {
			color: rgba(var(--font-supportive), 1);
		}
	}

	.input-wrap__input {
		background-color: rgba(var(--base-10), 1);
	}

	.two-fact__input-wrap input {
		background-color: rgba(var(--base-10), 1);

		&:focus {
			border: 1px solid rgba(var(--primary-accent-green), 1) !important;
			outline: none !important;
			outline-offset: 0 !important;
		}
	}

	.binary-question__yes,
	.binary-question__no,
	.binary-question__third {
		background: none;
		border-top: 1px solid rgba(var(--primary-accent-grey-dark), 1);
		color: rgba(var(--font-light), 1);
	}

	.binary-question__yes {
		border-left: 1px solid rgba(var(--primary-accent-grey-dark), 1);
		border-top: 1px solid rgba(var(--primary-accent-grey-dark), 1);
	}
}

// NOTIFY

.notify {
	/*
	&.notify--is-open {
		section {
			svg {
				fill: rgba(var(--base-02), 1);
			}
			h2,
			p {
				color: rgba(var(--base-02), 1);
			}
		}
	}
	*/
	&.notify--success {
		background-color: rgba(var(--primary-accent-grey-dark), 1) !important;
	}

	&.notify--failure {
		background-color: rgba(var(--error), 1) !important;
	}
}

.notify__head {
	.notify__head--quick-deposit {
		fill: var(--section-head-main-icon);
	}
}

// PROGRESS BAR

.notify__progress-bar {
	background-color: rgba(var(--primary-accent-grey-dark), 1);

	&.notify__progress-bar--fill-fail {
		background-color: rgba(var(--error), 1);
		background-image: none;
	}
}

// OPTION BOX
// OVERLAY BEHIND MODAL

.deposit-siru,
.binary-question {
	&.notify--is-open {
		background-color: rgba(var(--base-11), 0.85);
	}
}

// COOKIE CONSENT
// PAYMENT METHODS

.payment-methods {
	&__item {
		background-color: rgba(var(--base-10), 1);

		&__logo {
			svg.logo-payment-method {
				background-color: #fff;
			}
		}

		&__chevron {
			svg.link-list__continue-icon {
				fill: rgba(var(--primary-accent-green), 1);
			}
		}
	}
}

.payment-reminder {
	color: rgba(var(--base-02), 1);
	padding: 6px 0;
	white-space: pre-line;
}

// Two Factor auth on execute deposit

.two-fact {
	&__error-label {
		color: rgba(var(--error), 1);
	}

	.btn:disabled {
		background-color: inherit;
	}
}

.notify--is-open {
	background-color: rgba(var(--primary-accent-grey-dark), 0.85) !important;

	&.reality-check-confirm {
		background-color: rgba(var(--base-08), 1) !important;
	}

	h2 {
		text-transform: uppercase;
	}
}

.option-box {
	background-color: rgba(var(--base-11), 0.85);

	.option-box__inner {
		background-color: rgba(var(--base-10), 1);
		border-bottom: 1px solid rgba(var(--base-11), 1);
	}

	.btn--secondary {
		background-color: rgba(var(--base-10), 1);
	}

	.user-options {
		background-color: transparent;

		.filters {
			input {
				//TODO: fix this, reduce nesting REV-318
				//stylelint-disable-next-line selector-max-compound-selectors
				+ .filters__filter {
					background-color: rgba(var(--base-11), 1);
				}
				//TODO: fix this, reduce nesting REV-318
				//stylelint-disable-next-line selector-max-compound-selectors
				&:checked {
					//TODO: fix this, reduce nesting REV-318
					//stylelint-disable-next-line selector-max-compound-selectors
					+ .filters__filter {
						background-color: rgba(var(--primary-accent-green), 1);
						color: rgba(var(--base-01), 1);
					}
				}
			}
		}
	}
}

// components/quick-deposit

.quick-deposit-wrapper {
	background-color: rgba(var(--base-09), 1);
}

.quick-deposit-response {
	&.quick-deposit-response--pending {
		background-color: rgba(var(--base-10), 1);
	}
}

.quick-deposit-cvv-wrapper {
	.collapse__info-icon {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

// components/scroll-page-head

header.page-head {
	&.page-head--opac {
		background-color: rgba(var(--base-11), 1);
	}
}

// components/select

.custom-select {
	select {
		background-color: rgba(var(--base-10), 1);
		border-bottom: 1px solid rgba(var(--primary-accent-grey-light), 1);
	}

	select,
	option {
		color: rgba(var(--base-01), 1) !important;
	}

	select.placeholder-active {
		color: rgba(var(--base-06), 1);
	}

	legend {
		color: rgba(var(--input-placeholder-color), 1);
	}

	&::after {
		filter: brightness(0) saturate(100%) invert(68%) sepia(74%) saturate(462%) hue-rotate(78deg) brightness(102%)
			contrast(94%);
	}
}

.search-dropdown__input_container::after {
	filter: brightness(0) saturate(100%) invert(68%) sepia(15%) saturate(6963%) hue-rotate(34deg) brightness(98%)
		contrast(90%);
}
// components/spinner
// components/support-nav

.dots-container {
	svg {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

.support-nav {
	.btn--secondary {
		background-color: rgba(var(--base-10), 1);
	}

	&.reality-check-confirm {
		background-color: rgba(var(--info), 1) !important;
		background-image: none;

		.btn--secondary {
			background-color: rgba(#3099ea, 1);
			color: rgba(var(--base-01), 1);
		}
	}

	&.support-nav--is-open {
		background-color: rgba(var(--opacity-overlay), 0.8);

		.btn--secondary {
			background-color: rgba(var(--propose-locale-head-bg), 1);
			color: rgba(var(--base-01), 1);
		}
	}
}

// components/proposed-locale-nav
.propose-locale-nav {
	.propose-locale-wrapper {
		border-radius: 3px;

		.btn--secondary {
			border-bottom: 0;
			border-top: 1px solid rgba(var(--propose-locale-border, var(--base-07)), 1);
			color: rgba(var(--font-light), 1);
		}
	}
}

// components/tables

td {
	border-bottom: 1px solid rgba(var(--base-10), 1);

	&.u-text-r p {
		color: rgba(var(--font-supportive), 1);
	}

	&:first-of-type {
		font: var(--label-bold);
	}

	&:last-of-type {
		color: rgba(var(--font-supportive), 1);
	}
}

// components/tabs

/*
.bottom-wrapper {
	border-top: 1px solid rgba(var(--base-10), 1);
	&.signup--open,
	&.log-in--open {
		background-color: initial;
		border-top: 0;
	}
}
*/

.tab {
	* {
		color: rgba(var(--font-supportive), 1);
	}

	[aria-selected='true'] {
		span {
			color: rgba(var(--font-brand-01), 1);
		}

		svg {
			fill: rgba(var(--font-brand-01), 1);
		}
	}
}

// components/textarea

.form {
	fieldset {
		.grow-wrap:focus-within {
			~ .form__border {
				background-color: rgba(var(--primary-accent-green), 1);
			}
		}
	}

	textarea {
		background-color: rgba(var(--base-10), 1);
		color: rgba(var(--base-01));

		+ label,
		+ legend {
			color: rgb(var(--input-placeholder-color), 1);
		}
	}

	.form__border {
		&.form__border--error {
			background-color: rgba(var(--error), 1);
		}
	}

	.error {
		color: rgba(var(--error));
	}
}

// Autosize textarea
.grow-wrap {
	background-color: rgba(var(--base-10), 1);
	border-bottom: 1px solid rgba(var(--base-08), 1);

	textarea,
	&::after {
		color: rgba(var(--base-01));
	}
}

// components/winners
// layouts/bundles
// layouts/deposit

.deposit--open,
.withdrawal--open {
	.page-head {
		background-color: rgba(var(--primary-accent-grey), 1);
	}
}

.multichoice-list {
	.multichoice-list__item {
		border-bottom: 1px solid rgba(var(--base-07), 1);
	}
}

// layouts/footer

.paragraph-footer,
.paragraph-footer span * {
	color: rgba(var(--footer-text), 1);
	text-transform: none;

	a {
		color: rgba(var(--primary-accent-green), 1);
	}

	p {
		color: rgba(var(--footer-text), 1);
	}
}

.paragraph-footer span h2 {
	padding-bottom: 6px;
}

.footer-legal-content {
	color: rgba(var(--language-text), 1);
	font-weight: 400;

	a {
		color: rgba(var(--primary-accent-green), 1);
	}
}

.other-logos-footer {
	&.not-payment-logos-footer li {
		background-color: transparent;
	}
}

.bonus-step__hydration-spinner-bg-color {
	background-color: rgba(var(--base-11), 1);
}

// layouts/info-box

.info-box {
	background-color: rgba(var(--base-10), 1);

	.info-box-text,
	.info-box-value {
		color: rgba(var(--base-01), 1);
	}
}

// layouts/log-in

.svg-logo-container {
	.svg-logo {
		.shade1,
		.shade2 {
			fill: var(--base-02);
		}
	}
}

.bankid-logo-container {
	display: inline-block;

	svg,
	img {
		display: inline-block;
		height: 24px;
		margin-right: 12px;
		vertical-align: middle;

		.shade1,
		.shade2 {
			fill: var(--base-02);
		}
	}
}

// layouts/modal-view

.modal-view {
	background-color: rgba(var(--primary-accent-grey), 1);

	.page-head {
		background-color: inherit;
	}

	&.modal-view--verified {
		background-color: rgba(var(--success), 1);
	}

	&.modal-view--shop {
		background-color: rgba(var(--base-10), 1);
	}
}

.missing-data-dialog,
.sportsbook-relieve-limits-dialog,
.time-limits-dialog {
	background-color: rgba(var(--base-11), 1);

	.btn--skip {
		color: rgba(var(--base-02), 1);
	}
}

// Mentor

.game-types {
	td {
		border-bottom-color: rgba(var(--primary-accent-grey-light), 1);
	}
}

// layouts/my-limits

.my-limits-menu {
	.accordion {
		.accordion__link {
			background-color: rgba(var(--base-10), 1);
		}

		.accordion__content {
			background-color: rgba(var(--base-10), 1);
		}
	}

	button {
		color: rgba(var(--font-brand-01), 1);
	}
}

// layouts/off-canvas

.global-menu,
.account-menu {
	.page-head {
		background-color: rgba(var(--primary-accent-grey), 1);
	}
}

// layouts/off-canvas-account

.last-login-timer {
	&--text,
	&--status {
		color: rgba(var(--font-supportive), 1);
	}
}

.user-balance {
	.user-balance__title {
		color: rgba(var(--font-supportive), 1);
	}

	.user-balance__sum {
		color: rgba(var(--font-light), 1);
		text-transform: uppercase;
	}
}

.session-timer {
	color: rgba(var(--base-04), 1);

	&.session-timer--regulation-header {
		color: rgba(var(--base-12), 1);

		.session-timer-time {
			color: rgba(var(--base-01), 1);
		}
	}

	&.session-timer--regulation-header-ontario {
		color: rgba(var(--base-06), 1);

		.session-timer-time {
			color: inherit;
		}
	}
}

// layouts/payment
.imoje-banks {
	fill: none;
}

.selected-bank svg {
	border: 3px solid rgba(var(--primary-accent-green));
	border-radius: 3px;
}

.payment__form {
	.input-wrap .input-wrap__input {
		background-color: transparent;
	}
}

// layouts/pusher
// layouts/radio-box

.radio-box {
	background-color: rgba(var(--base-10), 1);

	h5,
	p {
		color: rgba(var(--font-light), 1);
	}

	small {
		color: rgba(var(--font-supportive), 1);
	}
}

// layouts/recover-password
// layouts/result

[class^='section-head__SubTitle'],
.u-result-header {
	color: rgba(var(--font-supportive), 1);
}

.result {
	&.result--is-open {
		.btn--secondary {
			background-color: rgba(var(--base-11), 0.25);
		}

		.js-notify__button-wrapper--failed .btn--secondary {
			background-color: rgba(var(--base-10), 1);
		}
	}
}

.js-payment-result-information-message {
	.js-try-again-button-header {
		color: rgba(var(--primary-accent-green), 1);
	}
}

.result.result--is-open .failed-deposit {
	.btn--secondary {
		background-color: rgba(var(--base-10), 1);
	}
}

.js-notify__button-wrapper--failed {
	.btn.btn--secondary {
		margin-bottom: 12px;

		&:first-child {
			border-radius: var(--button-border-radius);
		}
	}

	svg {
		fill: rgba(var(--primary-accent-green), 1);
	}
}
// layouts/risk

.risk-tag {
	background-color: rgba(var(--base-09), 1);
}

// layouts/shop

.points__svg {
	fill: rgba(var(--primary-accent-green), 1);

	text {
		fill: rgba(var(--base-02), 1);
	}
}

// layouts/signup
// layouts/sportsbook
// layouts/static-text

.wrapper-about,
.wrapper-responsible-gaming,
.wrapper-privacy_policy,
.wrapper-terms-and-conditions,
.wrapper-cookie-policy {
	.adaptable-header--unfixed {
		--page-head-title: rgba(var(--font-light), 1);
	}
}

.static-text.terms-conditions {
	.page-head {
		background-color: rgba(var(--primary-accent-grey), 1);
	}
}

// layouts/titles

.disclaimer-text {
	color: rgba(var(--primary-accent-green), 1);
	text-decoration: underline;
}

// layouts/transaction-history

.transaction-history-inactive-layer,
.transaction-modal {
	background-color: rgba(var(--primary-accent-grey), 1);
}

.transaction-modal {
	.pill {
		&.disable {
			.menu__main-title {
				color: rgba(var(--font-light), 1);
			}
		}
	}
}

// layouts/upload

.template-document-wrapper {
	border-bottom: 1px solid rgba(var(--base-07), 1);

	&::after {
		background-color: rgba(var(--primary-accent-green), 1);
	}
}

// layouts/welcome-screen

.user-balance-box {
	background-color: rgba(var(--base-02), 1);

	.welcome-balance-text,
	.welcome-balance-amount {
		color: rgba(var(--base-12), 1);
	}
}

.welcome-menu {
	.bottom-wrapper {
		background-color: rgba(var(--base-02), 1);
	}

	.link-list {
		.link-list__item {
			background-color: rgba(var(--primary-accent-green), 1);
		}

		.link-list__continue-icon {
			fill: rgba(var(--base-02), 1);
		}
	}
}

.player-info-screen {
	.balance-info {
		padding: 20px;
	}

	.button-wrapper,
	.balance-info {
		background-color: rgba(var(--base-02), 1);
	}
}

// layouts/wespin

.wrapper-we-spin {
	.transparent_title {
		background-color: transparent;
	}
}

.top-of-page-image-wrapper {
	.fade-image {
		&::after {
			background: linear-gradient(to top, rgba(var(--base-11), 1) 10%, rgba(var(--base-11), 0));
		}
	}
}

.item--large-we-spin {
	&.tutorial-card {
		p,
		small,
		#tutorial-card-link {
			color: rgba(var(--font-light), 1);
		}

		.btn--wespin {
			background-color: var(--button-primary-bg);
		}
	}

	small,
	small span {
		color: rgba(var(--font-light), 1);
	}

	&.we-spin-clip-card {
		small {
			color: rgba(var(--font-light), 1);
		}
	}
}

.avatar-circle {
	border: 2px solid rgba(var(--primary-accent-grey-dark), 1);

	.avatar-circle__flag {
		border: 2px solid rgba(var(--primary-accent-grey-dark), 1);
	}
}

.create-events-button,
.wespin-upload-file--button {
	background-color: transparent;
	border: 1px solid rgba(var(--primary-accent-green), 1);
	color: rgba(var(--primary-accent-green), 1);

	&:hover {
		@media (hover: hover) {
			border: 1px solid rgba(var(--primary-accent-green-light), 1);
			color: rgba(var(--primary-accent-green-light), 1);
		}
	}
}

.lobby-card__streamer-name {
	color: rgba(var(--primary-accent-green), 1);
}

.streamer-presentation {
	p {
		color: rgba(var(--base-06), 1);
	}
}

.display-circle {
	background-color: rgba(var(--base-08), 1);
}

.display-circle--svg {
	background-color: rgba(var(--base-10), 1);

	svg {
		fill: rgba(var(--primary-accent-green-light), 1);
	}
}

.buy-in-link {
	color: rgba(var(--primary-accent-green), 1);
}

.buy-in-link__disabled {
	color: rgba(var(--base-02), 1);
}

.upload-file {
	input[type='file'] + label {
		background-color: rgba(var(--base-10), 1);
		border-bottom: 1px solid rgba(var(--base-07), 1);
		color: rgba(var(--base-02), 1);
	}
}

.form {
	.custom-select--label {
		color: rgb(var(--input-placeholder-color), 1);
	}
}

.new {
	background-color: #a864ff;
	color: rgba(var(--base-01), 1);
}

.chevron-round {
	background-color: rgba(var(--primary-accent-green), 1);

	svg {
		fill: rgba(var(--base-01, 1)) !important;
	}
}

.chevron-round__disabled {
	background-color: rgba(var(--base-11), 1);
}

.wespin-gameroom-footer-prejoin {
	h5 {
		color: rgba(var(--base-02), 1);
	}

	button span {
		color: rgba(var(--font-dark), 1);
	}
}

.we-spin-buyin__collapse {
	.collapse-value {
		color: rgba(var(--font-supportive), 1);
	}
}

.wespin-gameroom-preview > .info-box.edit-buy-in {
	small {
		color: var(--font-light);
	}
}

.wespin-gameroom-preview > .info-box.edit-buy-in > .edit-buyin-button,
.buy-in-buttons div .btn {
	&.btn--small {
		background-color: rgba(var(--primary-accent-grey-dark), 1);
		border: 1px solid rgba(var(--primary-accent-green), 1);
		color: rgba(var(--primary-accent-green), 1);
	}

	&.btn--small.edit-buyin-button,
	&.btn--light {
		background-color: transparent;
		border: 1px solid rgba(var(--primary-accent-green), 1);
		color: rgba(var(--primary-accent-green), 1);
	}
}

.wespin-gameroom-footer-prejoin,
.wespin-gameroom-footer-stream-preview {
	border-top: 1px solid rgba(var(--primary-accent-green-accent-grey-light), 1);

	div {
		h5 > span {
			color: rgba(var(--font-supportive), 1);
		}

		span {
			color: rgba(var(--base-02), 1);
		}

		button {
			span {
				color: rgba(var(--base-01), 1);
			}
		}
	}
}

.wespin-gameroom-footer-stream-preview {
	h5 {
		color: rgba(var(--primary-accent-green), 1);
	}
}

.basic-horizontal-card-wrapper {
	background-color: rgba(var(--primary-accent-grey-dark), 1);

	.basic-horizontal-card--top-text small,
	.basic-horizontal-card--middle-text h5 {
		color: rgba(var(--font-light), 1);
	}

	.basic-horizontal-card--bottom-text small {
		color: rgba(var(--font-supportive), 1);
	}

	.basic-horizontal-card--bottom-tag span {
		color: rgba(var(--font-dark), 1);
	}

	.event-type-title-bg {
		background-color: rgba(var(--base-12), 1);
	}
}

.wespin-streamelement {
	small,
	p,
	h4 {
		color: rgba(var(--font-light), 1);
	}
}

.wespin-buyin-form,
.wespin-gameroom-preview {
	.collapse__info-icon,
	.collapse__continue-icon {
		fill: rgba(var(--primary-accent-green), 1);
	}

	.we-spin-game-room__collapse {
		margin-top: 16px;
	}
}

.wespin-large-buyin-button-container {
	border-top: 1px solid rgba(var(--primary-accent-green-accent-grey-light), 1);
}

.wespin-sidebar {
	header.page-head {
		&.page-head--opac {
			background-color: rgba(var(--primary-accent-grey), 1);

			div {
				button {
					background-color: transparent !important;
				}
			}
		}

		&.page-head.u-bg-trans {
			button {
				background-color: rgba(var(--primary-accent-grey), 1);
			}

			.wespin-gameroom-title {
				visibility: hidden;
			}
		}

		&.page-head--on-scroll {
			border-bottom: solid 1px rgba(var(--base-10), 1);
		}
	}
}

.we-spin-edit-stream-modal {
	button {
		border-radius: 3px;
	}
}

.page-head--we-spin {
	&.u-bg-trans {
		background-color: rgba(var(--primary-accent-grey), 1);
	}
}

/* Creating a streamer success page */
.create-streamer-success {
	.page-head__left-side {
		svg {
			fill: rgba(var(--base-01), 1);
		}
	}

	.create-streamer-success-body {
		p,
		h3 {
			color: rgba(var(--base-01), 1);
		}

		svg {
			fill: rgba(var(--base-01), 1);
		}
	}

	footer {
		button {
			background-color: rgba(var(--primary-accent-green), 1);
		}
	}
}

.BONUS_HUNT {
	background-image: radial-gradient(circle, rgba(128, 79, 193, 100%) 30%, rgba(220, 22, 199, 100%) 100%);
}

.BONUS_BUY {
	background-image: radial-gradient(circle, rgba(128, 79, 193, 100%) 30%, rgba(220, 22, 199, 100%) 100%);
}

.JACKPOT_HUNT {
	background-image: radial-gradient(circle, rgba(231, 242, 51, 100%) 30%, rgba(132, 233, 34, 100%) 100%);
}

.STREAMERS_CHOICE {
	background-image: linear-gradient(to right, rgb(0, 122, 173) 0%, rgb(165, 223, 249) 50%, rgb(0, 122, 173) 100%);
}

.RAFFLE {
	background-image: linear-gradient(
		to right,
		rgb(255, 223, 1) 0%,
		rgb(165, 124, 1) 40%,
		rgb(219, 183, 1) 80%,
		rgb(255, 223, 1) 100%
	);
}

// Onboarding
button {
	&.onboarding-secondary-button {
		background-color: rgba(var(--primary-accent-green), 1);
		color: rgba(var(--font-dark), 1);
		font: var(--label-bold);
	}

	&.onboarding-invisible-secondary-button {
		color: rgba(var(--primary-accent-green), 1);
		font: var(--label-regular);
	}
}

.we-spin-edit-stream-modal {
	button {
		background-color: rgba(var(--primary-accent-grey-light), 1);
	}
}

// layouts/withdrawal

.pending-withdrawal {
	border-bottom: 1px solid rgba(var(--base-07), 1);

	.pending-withdrawal__action {
		svg {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}

	.pending-withdrawal__amount {
		color: rgba(var(--font-supportive), 1);
	}
}

// layouts/SEO content

.seo-content {
	a {
		color: rgba(var(--primary-accent-green), 1);
	}
}

.quick-deposit-content {
	.link-list__item-link {
		background-color: rgba(15, 16, 16, 100%);
	}
}

// utils/placeholders
// utils/utility-classes

.u-box-bg {
	background-color: rgba(var(--base-11), 1);
}

.u-text-grey {
	color: rgba(var(--font-supportive), 1);
}

.u-divider {
	border-bottom: 1px solid rgba(var(--base-09), 1);
}

// resend phone verification related
.btn {
	&.didnt-get-code {
		background-color: transparent;
		font: var(--caption);
		margin-top: 14px;
		text-decoration: underline;
	}

	&.btn--secondary.resend-cta {
		&:disabled,
		span {
			color: rgba(var(--base-07), 1);
		}
	}
}

//support/zendesk
.support-button {
	background-color: rgba(var(--primary-accent-green), 1);
}

// Wespin EventTypeTitle BG color
.event-type-title-bg {
	background: rgba(var(--base-12), 1);
}

.top-of-page-image-wrapper .fade-image .event-type-title-bg.event-type-transparent .event-type-title-text {
	line-height: 70px;
}

.verification-message-helptext {
	font: var(--label-regular);
	position: absolute;
	right: 0;

	.message {
		color: inherit;
	}

	&.verified {
		color: rgba(var(--verification-message-verified-color), 1);
	}

	&.unverified {
		color: rgba(var(--verification-message-unverified-color), 1);

		svg {
			fill: rgba(var(--verification-message-unverified-color), 1);
		}
	}
}

.email-verification-helptext-wrapper {
	min-height: 250px;
}

.email-verification-input-helptext {
	color: rgba(var(--base-06), 1);
	font: var(--small);
}

.btn-send-mail-disabled {
	align-items: center;
	display: flex;
	justify-content: center;
}

.verify-mail-support-btn {
	span {
		color: rgba(var(--font-brand-01), 1);
	}
}
// Responsible Gaming

.my-limits-menu {
	.collapse-value {
		color: rgba(var(--font-supportive), 1);
	}
}

// Responsible Gaming Ontario

.deposit-limits-list.deposit-limits-collapse {
	div:first-of-type p {
		font: var(--label-bold);
	}
}

// Risk-profile

.risk-profile__description {
	.detail-view-header {
		p {
			color: rgba(var(--font-supportive), 1);
		}
	}
}

// components/payment/link-list
// Payment-provider tags

.payment-tag {
	color: rgba(var(--font-dark), 1);

	&__popular {
		background-color: rgba(var(--primary-accent-yellow), 1);
	}

	&__no-fee {
		background-color: rgba(var(--primary-accent-green), 1);
	}
}

p.circle-progress-bar {
	font: var(--label-regular);
}

div[class^='register-bonus-offer'] {
	background: rgba(var(--primary-accent-green-light), 1);

	* {
		color: rgba(var(--font-dark), 1);
	}
}

// Casino carousel
.hero {
	.carousel-title,
	.carousel-description {
		text-transform: uppercase;
	}
}

.qr-failure-icon {
	fill: rgba(var(--primary-accent-green-light), 1) !important;
}
